:root {
  --font: Helvetica, Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font);
}

a {
  color: #2796de;
  text-decoration-color: #bedff5;
}

a:hover {
  color: #000;
  text-decoration-color: rgba(0, 0, 0, 0.2);
}

input:is([type="date"]) {
  -webkit-appearance: textfield;
}

input::-webkit-date-and-time-value {
  text-align: start;
}
