.app {
  height: 100vh;
  height: 100dvh;
  min-height: 320px;
  min-width: 320px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  flex: 1;

  width: 100%;
  max-width: 360px;
  padding: 1em;

  display: flex;
  align-items: center;
  justify-content: center;
}
